import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import PARAMS from "utils/PARAMS";
import * as API from "utils/API_V2";
import Modal from "components/Modal/Modal";
import { toast } from 'react-toastify';
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from 'react-select';
import Loader from "react-spinners/CircleLoader";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const [idRecogida, setIdRecogida] = useState('');
  const [telefono, setTelefono] = useState('');
  const [tipo, setTipo] = useState('');
  const [causaAnulacion, setCausaAnulacion] = useState(null);
  const [causasAnulacion, setCausasAnulacion] = useState([]);
  const [observacionesAnulacion, setObservacionesAnulacion] = useState('');
  const [open, setOpen] = useState(false);
  const [modalId, setModalId] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [anular, setAnular] = useState(false);
  const [validId, setValidId] = useState(false);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (!open) {
      setIdRecogida('')
      setTelefono('')
      setObservacionesAnulacion('')
      setCausaAnulacion(null)
      setAnular(false)
      setValidId(false)
      setLoad(true)
    }
  }, [open]);

  useEffect(() => {
    if (anular && causasAnulacion.length == 0) {
      loadCausasAnulacion()
    }
  }, [anular]);

  useEffect(() => {
    localStorage.removeItem('recogidaKey');
    localStorage.removeItem('telefono');

    if (localStorage.getItem('confirmadoModal')) {
      setModalId(localStorage.getItem('confirmadoModal'));
      setIsEdit(false);
      setOpenConfirm(true);
    }
    if (localStorage.getItem('editadoModal')) {
      setModalId(localStorage.getItem('editadoModal'));
      setIsEdit(true);
      setOpenConfirm(true);
    }
  }, []);


  const loadCausasAnulacion = async() => {
    const res = await API.getCausasAnulacion(PARAMS.IdEmpresa, 1);
    if (res.error) {
      if (res.error && res.error.response) {
        toast(res.error.response.data.error, {type: "warning"});
      }
    }else{
      let data = JSON.parse(res.data);
      console.log(data);
      let array = [];
      data.forEach((item, i) => {
        let aux = {
          value: item.IdCausa,
          label: item.DCausa
        }
        array.push(aux);
      });

      setCausasAnulacion(array);
    }
  }

  const anularRecogida = async() => {
    if (causaAnulacion && causaAnulacion.value) {
      const res = await API.cambiaEstadoEnseresRaee("05", causaAnulacion.value, "", idRecogida, "Web", observacionesAnulacion);
      if (res.error) {
        if (res.error && res.error.response) {
          toast(res.error.response.data.error, {type: "warning"});
        }
      }else{
        toast("Tu recogida ha sido anulada", {type: "success"});
        setOpen(false);
      }
    }else{
      toast('Introduce una causa de anulación', {type: "warning"});
    }

  }

  const validarRecogida = async() => {
    setLoad(false);
    const res = await API.getRecogidaEnseresRaee(idRecogida, telefono);
    if (res.error) {
      if (res.error && res.error.response) {
        toast(res.error.response.data.Message, {type: "warning"});
      }else{
        toast('Recogida no encontrada', {type: "warning"});
      }
      setTimeout(function () {
        setLoad(true)
      }, 1000);
    }else{
      let data = JSON.parse(res.data)[0];
      console.log(data);
      if (data.IdEstado == '01') {
        localStorage.setItem('recogidaKey', idRecogida);
        localStorage.setItem('telefono', telefono);
        setTimeout(function () {
          setTipo(data.IdTipoElemento);
          setLoad(true)
          setValidId(true)
        }, 1000);
      }else{
        toast('Esta recogida no puede ser modificada', {type: "warning"});
      }
    }
  }

  const formConfirm = () => {
    return(<>
      {isEdit ? <GridItem xs={12} sm={12} md={12} lg={12} alignItems="center" style={{fontSize: 17, fontFamily: "LatoSemiBold", color: '#91c45e', padding: 15, marginBottom: 15}}>
        Su recogida con ID {modalId} ha sido editada
      </GridItem> : <GridItem xs={12} sm={12} md={12} lg={12} alignItems="center" style={{fontSize: 17, fontFamily: "LatoSemiBold", color: '#91c45e', padding: 15, marginBottom: 15}}>
        Su recogida con ID {modalId} ha sido confirmada
      </GridItem>}
    </>)
  }


  const formAnulacion = () => {
    return(<>
      <GridItem xs={12} sm={12} md={12} lg={12} alignItems="center" style={{fontSize: 17, fontFamily: "LatoSemiBold", color: '#91c45e', padding: 15}}>
        Introduzca la información de su Recogida
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <CustomInput
          labelText="Id de recogida"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            value: idRecogida,
            onChange: (e) => {
              setIdRecogida(e.target.value);
            }
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <CustomInput
          labelText="Teléfono"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            value: telefono,
            onChange: (e) => {
              setTelefono(e.target.value);
            }
          }}
        />
      </GridItem>

      {validId ? <>
        {!anular &&
        <>
          <GridItem xs={6} sm={6} md={6} lg={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Button color="primary" style={{backgroundColor: PARAMS.firstColor, fontSize: 17}} onClick={() => {
              if (tipo == '01') {
                window.location.href = `${PARAMS.baseUrl}gestion/enseres`
              }else{
                window.location.href = `${PARAMS.baseUrl}gestion/raee`
              }
            }}>Modificar</Button>
          </GridItem>
          <GridItem xs={6} sm={6} md={6} lg={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Button color="primary" style={{backgroundColor: PARAMS.firstColor, fontSize: 17}} onClick={() => setAnular(true)}>Anular</Button>
          </GridItem>
        </>}</>
      :
        <GridItem xs={12} sm={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {load ?
          <Button color="primary" style={{backgroundColor: PARAMS.firstColor, fontSize: 17}} onClick={() => {
            if (idRecogida && telefono) {
              validarRecogida()
            }else{
              toast('Introduce tu teléfono y una id de recogida', {type: "warning"});
            }
          }}>Buscar</Button>
        :
          <div style={{width: '100%', height: '100px', display: 'flex', justifyContent: 'center', marginTop: 15}}><Loader color={PARAMS.firstColor} size={45} /></div>
        }
        </GridItem>
      }

      {anular && <>
        <GridItem xs={12} sm={12} md={12} lg={12} alignItems="center" style={{fontSize: 17, fontFamily: "LatoSemiBold", color: '#91c45e', padding: 15}}>
          Seleccione una causa
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginBottom: 15, marginTop: 10}}>
          <Select
            value={causaAnulacion}
            onChange={(selectedOption) => {
              setCausaAnulacion(selectedOption);
            }}
            options={causasAnulacion}
            noOptionsMessage={() => 'No hay resultados'}
            placeholder="Selecciona una causa"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} alignItems="center" style={{fontSize: 17, fontFamily: "LatoSemiBold", color: '#91c45e', padding: 15, paddingBottom: 0}}>
          Observaciones de Anulación
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CustomInput
            labelText=""
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: observacionesAnulacion,
              onChange: (e) => {
                setObservacionesAnulacion(e.target.value);
              },
              multiline: true,
              rows: 4
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Button color="primary" style={{backgroundColor: PARAMS.firstColor, fontSize: 17}} onClick={() => anularRecogida()}>Confirmar</Button>
        </GridItem>
      </>}
    </>)
  }

  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={PARAMS.proyectName}
        rightLinks={<HeaderLinks isLogin={false} />}
        fixed
        isLogin={false}
        {...rest}
      />
      <GridItem xs={12} sm={12} md={12} style={{height: 120, backgroundColor: '#F2F2F2'}}></GridItem>
      <GridItem xs={12} sm={12} md={12} style={{height: 250, backgroundImage: `url(${require("assets/img/landing-bg.jpg")})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'center', alingItems: 'center', flexDirection: 'column', alignItems: 'center'}}>
              <h1 style={{fontWeigth: '900', fontFamily: 'LatoBold'}}>
                Enseres
              </h1>
              <h4 style={{fontWeigth: '900', fontFamily: 'LatoBold', color: '#91c45e'}}>
                Inicio | Enseres
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </GridItem>
      <div
        className={classes.pageHeader}
      >
        <div className={classes.container}>
          <ProductSection />
        </div>
      </div>
      <GridItem xs={12} sm={12} md={12} style={{height: 600, backgroundImage: `url(${require("assets/img/box.png")})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 150}}>
        <div className={classes.container}>
          <GridContainer style={{marginTop: 50}}>
            <GridItem xs={12} sm={12} md={12} lg={12} alignItems="center" justify="center" style={{fontSize: 28, fontFamily: "LatoSemiBold", color: '#646464', padding: 15, textAlign: 'center'}}>
              ¿Tienes una recogida confirmada?
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Button color="primary" style={{backgroundColor: PARAMS.firstColor, fontSize: 17}} onClick={() => setOpen(true)}>Modificar</Button>
            </GridItem>
            <Modal
              open={open}
              onCancel={() => setOpen(false)}
              content={formAnulacion()}
              noBtn={true}
            />
            <Modal
              open={openConfirm}
              onCancel={() => {
                setOpenConfirm(false)
                localStorage.removeItem('confirmadoModal');
                localStorage.removeItem('editadoModal');
              }}
              content={formConfirm()}
              noBtn={true}
            />
          </GridContainer>
        </div>
      </GridItem>
      <Footer whiteFont />
    </div>
  );
}
