// utils/API.js

import axios from "axios";
import PARAMS from "utils/PARAMS";
import { toast } from 'react-toastify';

// Instancia para api
var instance_sadeco = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 10000
});

instance_sadeco.interceptors.request.use(async function (config) {
  if (config.url !== "login/GetTokenRd") {
    const token = await getTokenRd('todoSerecicl@');
    if (token.error) {
      throw new instance_sadeco.Cancel('Operacion cancelada, no hay token');
    }else{
      config.headers.common["Authorization"] = "Bearer " + token.data;
    }
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

instance_sadeco.defaults.headers.common["Content-Type"] = "application/json";
instance_sadeco.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        window.location.replace(PARAMS.baseUrl);
        toast("Usuario no autorizado", {type: "error"});

        break;
      case 404:
        toast("Url no disponible", {type: "error"});
        break;
      case 500:
        break;
      default:

    }
  }else{
    toast("No se ha podido establecer conexión", {type: "error"});
  }

  return Promise.reject(error);
});

// Funcion auxiliar async
async function resolve(promise) {
  const resolved = {
    data: null,
    error: null
  };

  try {
    resolved.data = await promise;
  } catch(e) {
    resolved.error = e;
  }

  return resolved;
}

// --- Llamadas API Sadeco ---
export async function getTokenRd(IdString) {
  return await resolve(
    instance_sadeco.post('login/GetTokenRd', {IdString}).then(res => res.data)
  )
}

export async function getCallesFiltro(SiNo, Filtro) {
  return await resolve(
    instance_sadeco.post('Rd/getcallesfiltro', {SiNo, Filtro}).then(res => res.data)
  )
}

export async function getElementos(IdEmpresa, IdTipo01, IdTipo02, VisibleWeb) {
  return await resolve(
    instance_sadeco.post('Rd/getelementos', {IdEmpresa, IdTipo01, IdTipo02, VisibleWeb}).then(res => res.data)
  )
}

export async function getFechasLibres(IdEmpresa, FechaComprobacion, IdTipoElemento, IdTipoZona) {
  return await resolve(
    instance_sadeco.post('Rd/getfechaslibres', {IdEmpresa, FechaComprobacion, IdTipoElemento, IdTipoZona}).then(res => res.data)
  )
}

export async function chekCiudadanoDia(Telefono, Fecha, IdTipoElemento) {
  return await resolve(
    instance_sadeco.post('Rd/ChekCiudadanoDia', {Telefono, Fecha, IdTipoElemento}).then(res => res.data)
  )
}

export async function grabarEnseresRaeeTmp(IdEmpresa, IdTipoElemento, IdTipoZona, IdServicio, IdTurno, FechaPrevista, Login, MinutosExtra, OpcGrabar) {
  return await resolve(
    instance_sadeco.post('Rd/GrabarEnseresRaeeTmp', {IdEmpresa, IdTipoElemento, IdTipoZona, IdServicio, IdTurno, FechaPrevista, Login, MinutosExtra, OpcGrabar}).then(res => res.data)
  )
}

export async function borrarEnseresRaeeTmp(IdGuid) {
  return await resolve(
    instance_sadeco.post('Rd/BorrarEnseresRaeeTmp', {IdGuid}).then(res => res.data)
  )
}

export async function grabarEnseresRaee(Apellido1, Apellido2, CodigoPostal, CorreoElectronico, Escalera, EsDoble, FechaPrevista, IdBarrio, IdCalendario, IdCalle, IdEmpresa, IdEstado, IdRecogida, IdServicio, IdTipoElemento, IdTipoPeso, IdTipoZona, IdTurno, LoginCrea, Nombre, NombreCalle, Numero, ObservacionesCalle, Piso, Puerta, RazonSocial, Telefono1, Web, GeoLongitud, GeoLatitud, RdEnseresRaeeL) {
  return await resolve(
    instance_sadeco.post('Rd/GrabarEnseresRaee', {Apellido1, Apellido2, CodigoPostal, CorreoElectronico, Escalera, EsDoble, FechaPrevista, IdBarrio, IdCalendario, IdCalle, IdEmpresa, IdEstado, IdRecogida, IdServicio, IdTipoElemento, IdTipoPeso, IdTipoZona, IdTurno, LoginCrea, Nombre, NombreCalle, Numero, ObservacionesCalle, Piso, Puerta, RazonSocial, Telefono1, Web, GeoLongitud, GeoLatitud, RdEnseresRaeeL}).then(res => res.data)
  )
}

export async function getCausasAnulacion(IdEmpresa, VisibleWeb) {
  return await resolve(
    instance_sadeco.post('Rd/getcausasanulacion', {IdEmpresa, VisibleWeb}).then(res => res.data)
  )
}

export async function cambiaEstadoEnseresRaee(IdEstado, IdMotivoAnulacion, IdMotivoNoRecogida, IdRecogida, Login, ObservacionesNoRecogida) {
  return await resolve(
    instance_sadeco.post('Rd/CambiaEstadoEnseresRaee', {IdEstado, IdMotivoAnulacion, IdMotivoNoRecogida, IdRecogida, Login, ObservacionesNoRecogida}).then(res => res.data)
  )
}

export async function getRecogidaEnseresRaee(IdRecogida, Telefono1) {
  return await resolve(
    instance_sadeco.post('Rd/GetRecogidaEnseresRaee', {IdRecogida, Telefono1}).then(res => res.data)
  )
}

export async function enviarCorreoDetalleER(IdRecogida, Telefono1) {
  return await resolve(
    instance_sadeco.post('Rd/EnviarCorreoDetalleER', {IdRecogida, Telefono1}).then(res => res.data)
  )
}

export async function getCodigoConfirmacion(IdString) {
  return await resolve(
    instance_sadeco.post('Rd/GetCodigoConfirmacion', {IdString}).then(res => res.data)
  )
}

export async function getCodigoConfirmacionMail(IdString) {
  return await resolve(
    instance_sadeco.post('Rd/GetCodigoConfirmacionMail', {IdString}).then(res => res.data)
  )
}

export async function getServicios(IdEmpresa, IdServicio) {
  return await resolve(
    instance_sadeco.post('Rd/GetServicios', {IdEmpresa, IdServicio}).then(res => res.data)
  )
}
