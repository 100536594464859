import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

// core components
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FullCalendar from "components/FullCalendar/FullCalendar";
import "components/FullCalendar/calendar.scss";
import Maps from "components/Map/Maps";
import { toast } from 'react-toastify';
import Select from 'react-select';
import "moment/locale/es";
import Moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { Line } from 'rc-progress';
import AddIcon from '@material-ui/icons/Add';
import ForwardIcon from '@material-ui/icons/Forward';
import RemoveIcon from '@material-ui/icons/Remove';
import Check from "@material-ui/icons/Check";
import Loader from "react-spinners/CircleLoader";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Hidden from "@material-ui/core/Hidden";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as API from "utils/API_V2";
import PARAMS from "utils/PARAMS";
import styles from "assets/jss/material-kit-react/views/enseresPage";
import resumenDot from 'assets/img/resumen_dot.png';
import resumenDotEmpty from 'assets/img/resumen_dot_empty.png';
import resumenDotFill from 'assets/img/resumen_dot_fill.png';
import ban from 'assets/img/ban.png';

const useStyles = makeStyles(styles);
var time = null;
let interval = null;

const GreenCheckbox = withStyles({
  root: {
    color: PARAMS.firstColor,
    padding: 0,
    '&$checked': {
      color: PARAMS.firstColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


export default function EnseresPage(props) {

  const [editMode, setEditMode] = useState(false);

  const [cardAnimaton, setCardAnimation] = useState("cardHiddenRigth");
  const [isLoad, setIsLoad] = useState(false);
  const [step, setStep] = useState(1);
  const [stepNumber, setStepNumber] = useState(1);
  const [initCountdown, setInitCountdown] = useState(false);

  const [loadSearch, setLoadSearch] = useState(true);
  const [search, setSearch] = useState('');
  const [calle, setCalle] = useState(null);
  const [callesList, setCallesList] = useState([]);
  const [tipoElemento, setTipoElemento] = useState(null);

  const [date, setDate] = useState(null);
  const [dateId, setDateId] = useState(null);
  const [fechasLibres, setFechasLibres] = useState([]);

  const [elemento, setElemento] = useState(null);
  const [elementosList, setElementosList] = useState([]);
  const [elementos, setElementos] = useState([]);
  const [esDoble, setEsDoble] = useState(false);
  const [limiteCarga, setLimiteCarga] = useState(false);
  const [limiteSms, setLimiteSms] = useState(0);

  const [progressPercent, setProgressPercent] = useState(0);
  const [progressColor, setProgressColor] = useState(PARAMS.firstColor);
  const [ciudadano, setCiudadano] = useState({
    telefono: '',
    email: '',
    razon_social: '',
    nombre: '',
    apellido: '',
    apellido2: '',
    numero: '',
    escalera: '',
    piso: '',
    puerta: ''
  });
  const [numeroRecogidaTemp, setNumeroRecogidaTemp] = useState(null);
  const [numeroRecogida, setNumeroRecogida] = useState(null);
  const [codigoConfirmacion, setCodigoConfirmacion] = useState('');
  const [codigoConfirmacionInput, setCodigoConfirmacionInput] = useState('');
  const [validatedCode, setValidateCode] = useState(false);
  const [isMovilPhone, setIsMovilPhone] = useState(true);
  const [condicionesUso, setCondicionesUso] = useState(false);

  const selectRef = useRef(null);
  const cardRef = useRef(null);
  const countdownRef = useRef(null);

  useEffect(() => {
    let tipo_elemento = '';
    if(props.match.params.id == 'enseres'){
  	  tipo_elemento = '01';
  	}
    if(props.match.params.id == 'raee'){
      tipo_elemento = '02';
  	}
    setTipoElemento(tipo_elemento);

    let recogidaKey = localStorage.getItem('recogidaKey');
    let telefono = localStorage.getItem('telefono');

    if (recogidaKey && telefono) {
      loadRecogida(recogidaKey, telefono, tipo_elemento);
    }

    window.scrollTo(0, cardRef.current.offsetTop+100)
  }, []);

  useEffect(() => {
    setTimeout(function() {
      setCardAnimation("");
      setIsLoad(false);

      switch (true) {
        case (step == 2 && fechasLibres.length == 0):
          getFechasLibres();
          break;
        case (step == 4 && elementosList.length == 0):
          getElementos();
          break;
        default:
          setTimeout(function () {
            setIsLoad(true);
          }, 500);
      }

      setStepNumber(step);
    }, 700);

    if (step == 5 && !codigoConfirmacion && (numeroRecogidaTemp || editMode)) {
      getCodigoConfirmacion();
    }

  }, [step]);

  useEffect(() => {
    if (elementos && date) {
      checkPeso();
    }
  }, [elementos, editMode]);

  useEffect(() => {
    if (initCountdown) {
      startCountdown(PARAMS.tiempoReservaTmp)
    }else{
      clearInterval(interval);
    }
  }, [initCountdown]);

  const startCountdown = (duration) => {
    var timer = duration, minutes, seconds;
    interval = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      countdownRef.current.textContent = minutes + ":" + seconds;

      if (--timer < 0) {
        setInitCountdown(false);
        toast('Sesion caducada, selecciona cita de nuevo', {type: "warning"});
        setCardAnimation("cardHiddenRigth");
        borrarEnseresTmp();
        setStep(2);
      }
    }, 1000);
  }

  const loadRecogida = async(recogidaKey, telefono, tipo_elemento) => {
    const res = await API.getRecogidaEnseresRaee(recogidaKey, telefono);
    if (res.error) {
      if (res.error && res.error.response) {
        toast(res.error.response.data.Menssage, {type: "warning"});
      }
    }else{
      let data = JSON.parse(res.data)[0];
      if (data) {
        let editCalle = {
          value: data.IdCalle,
          label: data.NombreCalle,
          barrio: data.DBarrio,
          idBarrio: data.Barrio,
          cp: data.CodigoPostal,
          lat: data.GeoLatitud,
          lng: data.GeoLongitud
        };
        setCalle(editCalle);

        let editCiudadano = {
          telefono: data.Telefono1,
          email: data.CorreoElectronico,
          razon_social: '',
          nombre: data.Nombre,
          apellido: data.Apellido1,
          apellido2: '',
          numero: data.Numero,
          escalera: data.Escalera,
          piso: data.Piso,
          puerta: data.Puerta
        }
        setCiudadano(editCiudadano);

        getFechasLibres(data.FechaPrevista, data.IdTurno, data.IdServicio, data.SimpleDoble, tipo_elemento);

        let editElementos = [];
        data.RdEnseresRaeeL.forEach((item, i) => {
          let aux = {
            value: item.IdElemento,
            label: item.DElemento,
            cantidad: item.Cantidad,
            peso: item.Peso
          }
          editElementos.push(aux);
        });

        setElementos(editElementos);

      }else{
        toast('No se ha podido cargar los datos de la recogida, intentelo más tarde', {type: "warning"});
        setTimeout(function () {
          window.location.href = PARAMS.baseUrl;
        }, 2000);
      }
    }
  }

  const loadCalles = async(text) => {
    const res = await API.getCallesFiltro(0, text);
    if (res.error) {
      if (res.error && res.error.response) {
        toast(res.error.response.data.error, {type: "warning"});
      }
    }else{
      let data = JSON.parse(res.data);
      let array = [];
      data.forEach((item, i) => {
        let aux = {
          value: item.IdCalle,
          label: item.Nombre,
          barrio: item.DBarrio,
          idBarrio: item.Barrio,
          cp: item.CodigoPostal,
          lat: item.GeoLatitud,
          lng: item.GeoLongitud
        }
        array.push(aux);
      });

      setCallesList(array);
      setLoadSearch(true);
      if (array.length > 0) {
        selectRef.current.onMenuOpen();
      }else{
        selectRef.current.onMenuClose();
      }
    }
  }

  const getFechasLibres  = async(editFecha = null, editTurno = null, editServicio = null, editDoble = null, tipo_elemento = null) => {
    let typeElement = tipo_elemento ? tipo_elemento : tipoElemento;
    const res = await API.getFechasLibres("00001", Moment().format("YYYY/MM/DD"), typeElement, "01");
    if (res.error) {
      if (res.error && res.error.response) {
        toast(res.error.response.data.error, {type: "warning"});
      }
    }else{
      let data = JSON.parse(res.data);
      console.log(data)
      let array = [];
      let count = 0;
      let keyEdit = null;
      data.forEach((item, i) => {
        let start = Moment(item.Fecha).format("YYYY-MM-DD") + ' 08:00:00';
        let end = Moment(item.Fecha).format("YYYY-MM-DD")+ ' 14:00:00';
        if (item.IdTurno == '02') {
          start = Moment(item.Fecha).format("YYYY-MM-DD") + ' 16:00:00';
          end = Moment(item.Fecha).format("YYYY-MM-DD")+ ' 20:00:00';
        }

        // Indice del array de fechas correspondiente a la edición
        if (editFecha && (editFecha == item.Fecha) && editTurno && (editTurno == item.IdTurno)) {
          keyEdit = i;
        }

        if (item.DispSimples != 0 || item.DispDobles != 0 || keyEdit == i) {
          let aux = {
            id: count,
            start: start,
            end: end,
            backgroundColor: 'transparent',
            title: (item.IdTurno == '01') ? 'Mañana' : 'Tarde',
            className: `selected-event_${count}`,
            data:{
              fecha: item.Fecha,
              turno: item.IdTurno,
              servicio: item.IdServicio,
              disponible_simple: (keyEdit && editDoble == 'Simple') ? (item.DispSimples+1) : item.DispSimples,
              disponible_doble: (keyEdit && editDoble == 'Doble') ? (item.DispDobles+1) : item.DispDobles,
              margen_simple: item.MargenSimples,
              margen_doble: item.MargenDobles,
              peso: item.PesoEsDoble
            }
          }
          array.push(aux);
          count++;
        }
      });

      // Si la fecha/turno que selecciono no esta en disponible al editar
      if (editFecha && editTurno && keyEdit == null) {
        let auxServicio = await getSevicio(editServicio);
        let startEdit = Moment(editFecha).format("YYYY-MM-DD") + ' 08:00:00';
        let endEdit = Moment(editFecha).format("YYYY-MM-DD")+ ' 14:00:00';
        if (editTurno == '02') {
          startEdit = Moment(editFecha).format("YYYY-MM-DD") + ' 16:00:00';
          endEdit = Moment(editFecha).format("YYYY-MM-DD")+ ' 20:00:00';
        }
        let aux = {
          id: array.length,
          start: startEdit,
          end: endEdit,
          backgroundColor: 'transparent',
          title: (editTurno == '01') ? 'Mañana' : 'Tarde',
          className: `selected-event_${array.length}`,
          data:{
            fecha: editFecha,
            turno: editTurno,
            servicio: editServicio,
            disponible_simple: (editDoble == 'Simple') ? 1 : 0,
            disponible_doble: (editDoble == 'Doble') ? 1 : 0,
            margen_simple: auxServicio.MargenSimples,
            margen_doble: auxServicio.MargenDobles,
            peso: auxServicio.PesoEsDoble
          }
        }
        keyEdit = array.length;
        array.push(aux);
      }

      setFechasLibres(array);
      setTimeout(function () {
        setIsLoad(true);
      }, 500);

      if (keyEdit != null && array[keyEdit]) {
        setDate(array[keyEdit].data)
        setDateId(keyEdit)
        setEditMode(true)
        setStep(2);
      }

    }
  }

  const getElementos  = async() => {
    const res = await API.getElementos("00001", tipoElemento, tipoElemento, 1);
    if (res.error) {
      if (res.error && res.error.response) {
        toast(res.error.response.data.error, {type: "warning"});
      }
    }else{
      let data = JSON.parse(res.data);
      let array = [];
      data.forEach((item, i) => {
        let aux = {
          value: item.IdElemento,
          label: item.DElemento,
          peso: item.Peso,
          tipo_peso: item.DTipoPeso
        }
        array.push(aux);
      });
      setElementosList(array);
      setTimeout(function () {
        setIsLoad(true);
      }, 500);
    }
  }

  const grabarEnseresTmp = async() => {
    const res = await API.grabarEnseresRaeeTmp(PARAMS.IdEmpresa, tipoElemento, "01", date.servicio, date.turno, Moment(date.fecha).format("YYYY/MM/DD"), "Web", 30, "A");
    if (res.error) {
      if (res.error && res.error.response) {
        toast(res.error.response.data.error, {type: "warning"});
      }
    }else{
      let data = JSON.parse(res.data);
      setNumeroRecogidaTemp(data.IdTmp);
      setInitCountdown(true);
    }
  }

  const borrarEnseresTmp = async() => {
    const res = await API.borrarEnseresRaeeTmp(numeroRecogidaTemp);
    if (res.error) {
      if (res.error && res.error.response) {
        toast(res.error.response.data.error, {type: "warning"});
      }
    }else{
      setNumeroRecogidaTemp(null);
    }
  }

  const grabarEnseres = async() => {
    var idRecogida = 0
    if (editMode) {
      idRecogida = localStorage.getItem('recogidaKey');
    }

    let rdEnseresRaeeL = []
    elementos.forEach(function(elem, index) {
      let aux = {
        "IdElemento": elem.value,
        "Cantidad": elem.cantidad,
        "Peso": elem.peso
      }
      rdEnseresRaeeL.push(aux);
    });
    const res = await API.grabarEnseresRaee(ciudadano.apellido, ciudadano.apellido2, calle.cp, ciudadano.email, ciudadano.escalera, +esDoble, Moment(date.fecha).format("YYYY/MM/DD"), calle.idBarrio, "01", calle.value, PARAMS.IdEmpresa, "01", idRecogida, date.servicio, tipoElemento, "01", "01", date.turno, "Web", ciudadano.nombre, calle.label, ciudadano.numero, "", ciudadano.piso, ciudadano.puerta, ciudadano.razon_social, ciudadano.telefono, 1, calle.lng, calle.lat, rdEnseresRaeeL);
    if (res.error) {
      if (res.error && res.error.response) {
        toast(res.error.response.data.error, {type: "warning"});
      }
    }else{
      let data = JSON.parse(res.data);
      setNumeroRecogida(data);
      enviarEmail(data);
      if (editMode) {
        toast(`Recogida Id - ${idRecogida} editada`, {type: "success"});
        setTimeout(function () {
          localStorage.setItem('editadoModal', idRecogida);
          window.location.href = PARAMS.baseUrl;
        }, 3000);
      }else{
        toast(`Recogida Id - ${data} confirmada`, {type: "success"});
        setTimeout(function () {
          localStorage.setItem('confirmadoModal', data);
          window.location.href = PARAMS.baseUrl;
        }, 3000);
      }
    }
  }

  const enviarEmail = async(num) => {
    const res = await API.enviarCorreoDetalleER(num, ciudadano.telefono);
    if (res.error) {
      if (res.error && res.error.response) {
        toast(res.error.response.data.error, {type: "warning"});
      }
    }else{
      console.log(res.data);
    }
  }

  const getCodigoConfirmacion = async() => {
    if (limiteSms < 3) {
      if (isMovilPhone) {
        codigoSms();
      }else{
        codigoMail();
      }
    }else{
      if (validatedCode) {
        toast('El código ya ha sido confirmado', {type: "warning"});
      }else{
        toast('Limite de reenvios alcanzado', {type: "warning"});
      }
    }
  }

  const codigoSms = async() => {
    const res = await API.getCodigoConfirmacion(ciudadano.telefono);
    if (res.error) {
      if (res.error && res.error.response) {
        toast(res.error.response.data.error, {type: "warning"});
      }
    }else{
      setCodigoConfirmacion(res.data);
      setLimiteSms(limiteSms => limiteSms + 1)
    }
  }

  const codigoMail = async() => {
    const res = await API.getCodigoConfirmacionMail(ciudadano.email);
    if (res.error) {
      if (res.error && res.error.response) {
        toast(res.error.response.data.error, {type: "warning"});
      }
    }else{
      setCodigoConfirmacion(res.data);
      setLimiteSms(limiteSms => limiteSms + 1)
    }
  }

  const getSevicio = async(idServicio) => {
    const res = await API.getServicios(PARAMS.IdEmpresa, idServicio);
    if (res.error) {
      if (res.error && res.error.response) {
        toast(res.error.response.data.error, {type: "warning"});
      }
    }else{
      let data = JSON.parse(res.data)[0];
      if (data) {
        return data;
      }else{
        toast('No se ha podido cargar los datos del servicio, intentelo más tarde', {type: "warning"});
        setTimeout(function () {
          window.location.href = PARAMS.baseUrl;
        }, 2000);
      }
    }
  }

  const nextStep = async() => {
    let valid = false;
    switch (step) {
      case 1:
        if (calle) {
          valid = true;
        }else{
          toast('Introduce la dirección de recogida', {type: "warning"});
        }
        break;
      case 2:
        if (date) {
          valid = true;
          if (!numeroRecogidaTemp && !editMode) {
            grabarEnseresTmp();
          }
        }else{
          toast('Introduce la fecha de recogida', {type: "warning"});
        }
        break;
      case 3:
        if (validCiudadano()) {
          valid = true;
          if (!/([0-9]){9}/.test(ciudadano.telefono)) {
            toast('Teléfono no válido', {type: "warning"});
            valid = false;
          }
          if (/(9|8)([0-9]){8}/.test(ciudadano.telefono)) {
            setIsMovilPhone(false);
          }else{
            setIsMovilPhone(true);
          }
          if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(ciudadano.email)) {
            toast('Email no válido', {type: "warning"});
            valid = false;
          }
          // Validacion de usuario ya tiene recogida ese dia
          const res = await API.chekCiudadanoDia(ciudadano.telefono, Moment(date.fecha).format("YYYY/MM/DD"), tipoElemento);
          if (res.error) {
            if (res.error && res.error.response) {
              toast(res.error.response.data.error, {type: "warning"});
            }
          }else{
            if (res.data != '0') {
              toast(`Ya tienes un reserva ese día (ID: ${res.data})`, {type: "warning"});
              valid = false;
            }
          }
        }else{
          toast('Introduce tus datos', {type: "warning"});
        }
        break;
      default:
        valid = true
    }

    if (valid && cardAnimaton == '') {
      setCardAnimation("cardHiddenLeft");
      setStep(step => step + 1);
      window.scrollTo(0, cardRef.current.offsetTop+150)
    }
  }

  const validCiudadano = () => {
    if (ciudadano.telefono == '') {
      return false;
    }
    if (ciudadano.email == '') {
      return false;
    }
    if (ciudadano.nombre == '') {
      return false;
    }
    if (ciudadano.apellido == '') {
      return false;
    }
    if (ciudadano.numero == '') {
      return false;
    }
    return true;
  }

  const backStep = () => {
    if (cardAnimaton == '') {
      setCardAnimation("cardHiddenRigth");
      setStep(step => step - 1);
    }
  }

  const guardarCita = () => {
    if (!condicionesUso) {
      toast("Debes aceptar las condiciones de uso", {type: "warning"});
      return false;
    }

    if (elementos.length > 0) {
      if (!limiteCarga) {
        toast('Limite de carga superado', {type: "warning"});
      }else{
        if (cardAnimaton == '') {
          setCardAnimation("cardHiddenLeft");
          setStep(step => step + 1);
        }
      }
    }else{
      toast('Introduce los elementos a recoger', {type: "warning"});
    }
  }

  const addElemento = () => {
    if (elemento) {
      if (elementos.find(el => el.label == elemento.label)) {
        toast('El elemento ya ha sido añadido', {type: 'warning'})
      }else{
        setElementos([...elementos, {value: elemento.value, label: elemento.label, cantidad: 1, peso: elemento.peso}])
      }
    }else{
      toast('Selecciona un elemento', {type: 'warning'})
    }
  }

  const checkPeso = () => {
    setEsDoble(false);
    setLimiteCarga(false);
    let disponible_simple = date.disponible_simple;
    let disponible_doble = date.disponible_doble;
    let margen_simple = date.margen_simple;
    let margen_doble = date.margen_doble;
    let peso = date.peso;
    let peso_actual = 0;

    elementos.forEach((item, i) => {
      peso_actual += (item.peso*item.cantidad);
    });

    if (elementos.length > 0) {
      if (peso_actual > peso+margen_simple) {
        setEsDoble(true);
      }

      if (disponible_doble > 0) {
        if (peso_actual < peso*2+margen_doble) {
          setLimiteCarga(true);
        }else{
          toast('Limite de carga superado', {type: "warning"});
        }

        let porcentaje_doble = Math.round(peso_actual*100/(peso*2+margen_doble)*100)/100;
        loadProgressBar(porcentaje_doble)
      }else{
        if (peso_actual < peso+margen_simple) {
          setLimiteCarga(true);
        }else{
          toast('Limite de carga superado', {type: "warning"});
        }

        let porcentaje_simple = Math.round(peso_actual*100/(peso+margen_simple)*100)/100;
        loadProgressBar(porcentaje_simple)
      }
    }else{
      loadProgressBar(0)
    }
  }

  const removeElemento = (index) => {
    var aux = [...elementos];
    if (index !== -1) {
      aux.splice(index, 1);
      setElementos(aux)
    }
  }

  const loadProgressBar = (porcentaje) => {
    setProgressPercent(porcentaje);
    switch (true) {
      case (porcentaje > 25 && porcentaje <= 50):
        setProgressColor('#E4B006');
        break;
      case (porcentaje > 50):
        setProgressColor('#FF4949');
        break;
      default:
        setProgressColor(PARAMS.firstColor);
    }
  }

  const triggerSearch = (text) => {
    setSearch(text)
    if (text.length >= 3) {
      setLoadSearch(false);
      clearInterval(time);
      time = setTimeout(function(){
        loadCalles(text);
      },500);
    }else{
      setLoadSearch(true);
    }
  }

  const renderResumen = () => {
    return(<Hidden smDown implementation="css">
      <GridContainer style={{margin: "100px 15% 0px 15%"}}>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginBottom: 20}}>
          <GridContainer direction="row" style={{fontFamily: "LatoBlack"}}>
            <GridItem style={{width: '20%', textAlign: 'center', padding: 0}}>
              {step == 1 && <span className={classes.title1} style={{fontSize: 15, color: '#91c45e', padding: 0}}>PASO 1:</span>} {calle && <span className={classes.subTitle1}>{calle.label} {calle.cp}</span>}
            </GridItem>
            <GridItem style={{width: '20%', textAlign: 'center', padding: 0}}>
              {step == 2 && <span className={classes.title1} style={{fontSize: 15, color: '#91c45e', padding: 0}}>PASO 2:</span>} {date && <span className={classes.subTitle1}>{Moment(date.fecha).format("DD MMMM")}
              {date.turno == '01' ? ' - Por la mañana' : ' - Por la tarde'}</span>}
            </GridItem>
            <GridItem style={{width: '20%', textAlign: 'center', padding: 0}}>
              {step == 3 && <span className={classes.title1} style={{fontSize: 15, color: '#91c45e', padding: 0}}>PASO 3:</span>} {validCiudadano() && <span className={classes.subTitle1}>{ciudadano.nombre} {ciudadano.apellido}</span>}
            </GridItem>
            <GridItem style={{width: '20%', textAlign: 'center', padding: 0}}>
              {step == 4 && <span className={classes.title1} style={{fontSize: 15, color: '#91c45e', padding: 0}}>PASO 4:</span>} {elementos.length > 0 && <span className={classes.subTitle1}>{progressPercent} %</span>}
            </GridItem>
            <GridItem style={{width: '20%', textAlign: 'center', padding: 0}}>
              {step == 5 && <span className={classes.title1} style={{fontSize: 15, color: '#91c45e', padding: 0}}>PASO 5:</span>} {calle && <span className={classes.subTitle1}>Resumen</span>}
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={12} style={{height: 9, backgroundColor: '#ECECEC'}}>
          <GridContainer direction="row">
            <GridItem style={{width: '2%'}}/>
            <GridItem style={{width: '5%', padding: 0, cursor: 'pointer'}}>
              <img src={(step == 1) ? resumenDot : (calle ? resumenDotFill : resumenDotEmpty)} style={{marginTop: -15, cursor: 'pointer'}} onClick={() => {
                if (calle && cardAnimaton == '') {
                  setCardAnimation("cardHiddenRigth");
                  setStep(1);
                }
              }}/>
            </GridItem>
            <GridItem style={{width: '17.5%'}}/>
            <GridItem style={{width: '5%', padding: 0}}>
              <img src={(step == 2) ? resumenDot : (date ? resumenDotFill : resumenDotEmpty)} style={{marginTop: -15, cursor: 'pointer'}} onClick={() => {
                if (date && cardAnimaton == '') {
                  setCardAnimation("cardHiddenRigth");
                  setStep(2);
                }
              }}/>
            </GridItem>
            <GridItem style={{width: '17.5%'}}/>
            <GridItem style={{width: '5%', padding: 0}}>
              <img src={(step == 3) ? resumenDot : (validCiudadano() ? resumenDotFill : resumenDotEmpty)} style={{marginTop: -15, cursor: 'pointer'}} onClick={() => {
                if (validCiudadano() && cardAnimaton == '') {
                  setCardAnimation("cardHiddenRigth");
                  setStep(3);
                }
              }}/>
            </GridItem>
            <GridItem style={{width: '17.5%'}}/>
            <GridItem style={{width: '5%', padding: 0}}>
              <img src={(step == 4) ? resumenDot : (elementos.length > 0 ? resumenDotFill : resumenDotEmpty)} style={{marginTop: -15, cursor: 'pointer'}} onClick={() => {
                if (elementos.length > 0 && cardAnimaton == '') {
                  setCardAnimation("cardHiddenRigth");
                  setStep(4);
                }
              }}/>
            </GridItem>
            <GridItem style={{width: '17.5%'}}/>
            <GridItem style={{width: '5%', padding: 0}}>
              <img src={(step == 5) ? resumenDot : (editMode ? resumenDotFill : resumenDotEmpty)} style={{marginTop: -15, cursor: 'pointer'}} onClick={() => {
                if (cardAnimaton == '') {
                  setCardAnimation("cardHiddenRigth");
                  setStep(5);
                }
              }}/>
            </GridItem>
            <GridItem style={{width: '2%'}}/>
          </GridContainer>
        </GridItem>

      </GridContainer>
    </Hidden>)
  }

  const renderStep = () => {
    if (!isLoad) {
      return(
        <GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
          <Loader color={PARAMS.firstColor} size={60} />
          <span style={{fontSize: 30, fontWeigth: 900, fontFamily: 'Roboto', color: PARAMS.firstColor, marginLeft: 15}}>Sadeco Enseres</span>
        </GridContainer>
      )
    }

    if (stepNumber == 1) {
      return(<GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12} className={classes.title1} style={{textAlign: 'center'}}>
          Escriba una calle (para comprobar si hay servicio en su zona)
          {editMode && <><br/><b style={{color: '#000'}}>La calle no se puede editar</b></>}
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CustomInput
            labelText="Escriba el nombre de la Calle"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: search,
              onChange: (e) => {
                triggerSearch(e.target.value);
              },
              disabled: editMode
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} className={classes.title1} style={{textAlign: 'center'}}>
          Seleccione una ubicación del desplegable
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginBottom: 15, marginTop: 10}}>
          <Select
            value={calle}
            onChange={(selectedOption) => {
              setCalle(selectedOption);
            }}
            isDisabled={editMode}
            options={callesList}
            noOptionsMessage={() => 'No hay resultados'}
            placeholder="Selecciona un calle"
            ref={selectRef}
            maxMenuHeight={200}
          />
          {!loadSearch && <div style={{marginTop: -25, marginLeft: -25}}><Loader color={PARAMS.firstColor} size={20} /></div>}
        </GridItem>
      </GridContainer>)
    }

    if (stepNumber == 2) {
      return(<GridContainer  alignItems="flex-start" direction="row">
        <GridItem xs={12} sm={12} md={12} lg={12} className={classes.title1} style={{textAlign: 'center'}}>
          Seleccione un día y un tramo horario (mañana o tarde)
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: 30}}>
          <FullCalendar
            events={fechasLibres}
            horario={{start: '08:00', end: '20:00'}}
            setDate={(value) => setDate(value)}
            setDateId={(value) => setDateId(value)}
            dateId={dateId}
          />
        </GridItem>
      </GridContainer>)
    }

    if (stepNumber == 3) {
      return(<GridContainer  alignItems="flex-start" direction="row">
        <GridItem xs={12} sm={12} md={12} lg={12} className={classes.title1} style={{textAlign: 'center', marginBottom: 30}}>
          Rellene los datos necesarios para la recogida
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          {(ciudadano.telefono == '') && <span className={classes.requerido}>(Campo obligatorio)</span>}
          <CustomInput
            labelText="Teléfono"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ciudadano.telefono,
              onChange: (e) => {
                setCiudadano({...ciudadano, telefono: e.target.value});
              }
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          {(ciudadano.email == '') && <span className={classes.requerido}>(Campo obligatorio)</span>}
          <CustomInput
            labelText="Email"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ciudadano.email,
              onChange: (e) => {
                setCiudadano({...ciudadano, email: e.target.value});
              }
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          <CustomInput
            labelText="Razón Social"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ciudadano.razon_social,
              onChange: (e) => {
                setCiudadano({...ciudadano, razon_social: e.target.value});
              }
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          {(ciudadano.nombre == '') && <span className={classes.requerido}>(Campo obligatorio)</span>}
          <CustomInput
            labelText="Nombre"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ciudadano.nombre,
              onChange: (e) => {
                setCiudadano({...ciudadano, nombre: e.target.value});
              }
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          {(ciudadano.apellido == '') && <span className={classes.requerido}>(Campo obligatorio)</span>}
          <CustomInput
            labelText="Apellido"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ciudadano.apellido,
              onChange: (e) => {
                setCiudadano({...ciudadano, apellido: e.target.value});
              }
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          <CustomInput
            labelText="Apellido 2"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ciudadano.apellido2,
              onChange: (e) => {
                setCiudadano({...ciudadano, apellido2: e.target.value});
              }
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CustomInput
            labelText="Calle (Campo rellenado anteriormente)"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: calle.label,
              disabled: true
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={3} md={3} lg={3}>
          {(ciudadano.numero == '') && <span className={classes.requerido}>(Campo obligatorio)</span>}
          <CustomInput
            labelText="Número"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ciudadano.numero,
              onChange: (e) => {
                setCiudadano({...ciudadano, numero: e.target.value});
              }
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={3} md={3} lg={3}>
          <CustomInput
            labelText="Escalera"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ciudadano.escalera,
              onChange: (e) => {
                setCiudadano({...ciudadano, escalera: e.target.value});
              }
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={3} md={3} lg={3}>
          <CustomInput
            labelText="Piso"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ciudadano.piso,
              onChange: (e) => {
                setCiudadano({...ciudadano, piso: e.target.value});
              }
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={3} md={3} lg={3}>
          <CustomInput
            labelText="Puerta"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ciudadano.puerta,
              onChange: (e) => {
                setCiudadano({...ciudadano, puerta: e.target.value});
              }
            }}
          />
        </GridItem>
      </GridContainer>)
    }

    if (stepNumber == 4) {
      return(<GridContainer  alignItems="flex-start" direction="row">
        <GridItem xs={12} sm={12} md={12} lg={12} className={classes.title1} style={{textAlign: 'center'}}>
          Selecciona los elementos que SADECO recogerá
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginBottom: 10}}>
          <div style={{width: (progressPercent <= 100) ? `${progressPercent}%` : "100%", display: 'flex', justifyContent: 'flex-end', alingItems: 'flex-end'}}><img src={ban} style={{marginRight: -35, marginBottom: 15}}/></div>
          <Line percent={progressPercent} strokeWidth="2" strokeColor={progressColor} />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6} style={{marginBottom: 15, display: 'flex', justifyContent: 'flex-start', padding: 0}}>
          <span className={classes.title1} style={{color: '#BDBDBD', fontFamily: 'LatoItalic', padding: 0}}>Carga al {progressPercent}%</span>
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6} style={{marginBottom: 15, display: 'flex', justifyContent: 'flex-end', padding: 0}}>
          <span className={classes.title1} style={{color: '#BDBDBD', fontFamily: 'LatoItalic', padding: 0}}>Carga al 100%</span>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{padding: '0 3%', marginTop: 15}}>
          <GridContainer>
            <GridItem xs={10} sm={10} md={10} lg={10}>
              <Select
                value={elemento}
                onChange={(selectedOption) => {
                  setElemento(selectedOption);
                }}
                options={elementosList}
                placeholder="Selecciona un elemento"
                maxMenuHeight={200}
              />
            </GridItem>
            <GridItem xs={2} sm={2} md={2} lg={2} style={{display: 'flex'}}>
              <Button color="primary" style={{backgroundColor: PARAMS.firstColor, maxWidth: 50, marginTop: -5}} onClick={() => addElemento()}><AddIcon style={{fontSize: 30}}/></Button>
            </GridItem>
          </GridContainer>
        </GridItem>
        {elementos.map(function(elem, index) {
          return (<GridItem xs={12} sm={12} md={12} lg={12} style={{padding: '0 3%', marginTop: 15}} key={index}>
            <GridContainer alignItems="flex-start" direction="row">
              <GridItem xs={8} sm={8} md={8} lg={8} >
                <CustomInput
                  labelText="Elemento"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: elem.label,
                    disabled: true
                  }}
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2} lg={2}>
                <CustomInput
                  labelText="Cantidad"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: elem.cantidad,
                    onChange: (e) => {
                      const { value } = e.target;
                      var aux = [...elementos];
                      if (value < 1) {
                        aux[index].cantidad = 1;
                      }else{
                        aux[index].cantidad = value;
                      }
                      setElementos(aux);
                    },
                    type: "number",
                    min: 0,
                    max: 1000
                  }}
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2} lg={2} style={{display: 'flex'}}>
                <Button color="primary" style={{backgroundColor: '#FF4949', maxWidth: 50}} onClick={() => removeElemento(index)}><RemoveIcon style={{fontSize: 30}}/></Button>
              </GridItem>
            </GridContainer>
          </GridItem>);
        })}

      </GridContainer>)
    }

    if (stepNumber == 5) {
      return(<GridContainer  alignItems="flex-start" direction="row">
        <GridItem xs={12} sm={12} md={12} lg={12} className={classes.title1} style={{textAlign: 'center', marginBottom: 15}}>
          Confirmación y resumen de la recogida
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{fontFamily: 'LatoSemiBold', fontSize: 20, color: '#595959', textAlign: 'center', marginBottom: 30, fontWeigth: 'bold'}}>
          {isMovilPhone ? 'A continuación, recibirá un código de confirmación de su solicitud en su teléfono móvil mediante SMS. Necesitará introducirlo en el apartado, “inserte el número que le ha llegado por SMS a su móvil”, para que el servicio quede finalmente reservado. En caso de no realizar este paso, el servicio no se registrará y por lo tanto no se prestará' : 'A continuación, recibirá un código de confirmación de su solicitud en su Email. Necesitará introducirlo en el apartado, “inserte el número que le ha llegado por Email”, para que el servicio quede finalmente reservado. En caso de no realizar este paso, el servicio no se registrará y por lo tanto no se prestará'}
        </GridItem>
        <GridItem xs={6} sm={5} md={4} lg={4} style={{paddingRight: 0}}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <p style={{fontFamily: 'LatoSemiBold', fontSize: 14, color: '#8D8D8D'}}>Calle:</p>
              <p style={{fontFamily: 'LatoSemiBold', fontSize: 17, color: '#595959', marginBottom: 15}}>
                {calle.label} - {calle.cp}
              </p>

              <p style={{fontFamily: 'LatoSemiBold', fontSize: 14, color: '#8D8D8D'}}>Fecha:</p>
              <p style={{fontFamily: 'LatoSemiBold', fontSize: 17, color: '#595959', marginBottom: 15}}>
                {Moment(date.fecha).format("DD MMMM")}
                {date.turno == '01' ? ' Mañana' : ' Tarde'}
              </p>

              <p style={{fontFamily: 'LatoSemiBold', fontSize: 14, color: '#8D8D8D'}}>Teléfono:</p>
              <p style={{fontFamily: 'LatoSemiBold', fontSize: 17, color: '#595959', marginBottom: 15}}>
                {ciudadano.telefono}
              </p>

              <p style={{fontFamily: 'LatoSemiBold', fontSize: 14, color: '#8D8D8D'}}>Elementos:</p>
              {elementos.map(function(elem, index) {
                return(
                  <p style={{fontFamily: 'LatoSemiBold', fontSize: 17, color: '#595959', marginBottom: 15}}>
                    {elem.cantidad} {elem.label}
                  </p>
                )
              })}
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={6} sm={7} md={8} lg={8} style={{display: 'flex', justifyContent: 'center', alingItems: 'center', padding: 0}}>
          <Maps lat={calle ? calle.lat : null} lng={calle ? calle.lng : null} width="45vw"/>
        </GridItem>
        <GridItem xs={12} sm={6} md={4} lg={4} style={{display: 'flex', alignItems: 'center', marginTop: 45, flexDirection: 'row'}}>
          <div style={{backgroundColor: '#BDBDBD', borderRadius: 15, padding: 10, display: 'flex', alignItems: 'center'}}>
            <CustomInput
              white
              labelText="Código de confirmación"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: codigoConfirmacionInput,
                onChange: (e) => {
                  const { value } = e.target;
                  setCodigoConfirmacionInput(value);
                  if (value == codigoConfirmacion) {
                    setLimiteSms(3);
                    setValidateCode(true);
                  }else{
                    if (codigoConfirmacion) {
                      setValidateCode(false);
                    }
                  }
                }
              }}
              success={validatedCode}
            />
            <div style={{width: 50, height: 40}}>
              {validatedCode && <Check style={{color: 'white', fontSize: 40, marginLeft: 15}}/>}
            </div>
          </div>
        </GridItem>
        <GridItem xs={12} sm={6} md={8} lg={8} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 70}}>
          {numeroRecogida && <p style={{fontFamily: 'LatoSemiBold', fontSize: 30, color: PARAMS.firstColor}}>ID Recogida: {numeroRecogida}</p>}
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: 10}}>
          <p style={{fontFamily: 'LatoSemiBold', fontSize: 14, color: PARAMS.firstColor}}>
            {isMovilPhone ? 'Inserte el número que le ha llegado por SMS a su móvil' : 'Inserte el número que le ha llegado por Email'}
          </p>
          <Button color="primary" style={{backgroundColor: PARAMS.firstColor, fontSize: 15}} onClick={() => getCodigoConfirmacion()}>{isMovilPhone ? 'Reenviar SMS ' : 'Reenviar Email '}<ForwardIcon style={{fontSize: 30, marginLeft: 10}}/></Button>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: 20}}>
          {date.turno == '01' ?
            <p style={{fontFamily: 'LatoSemiBold', fontSize: 14, color: '#8D8D8D'}}>Hora de recogida: Entre las 8:00 y las 14:00H. Debe depositar los objetos a recoger en el portal antes de las 8:00H del día de la recogida.</p>
          :
            <p style={{fontFamily: 'LatoSemiBold', fontSize: 14, color: '#8D8D8D'}}>Hora de recogida: Entre las 14:00 y las 21:00H. Debe depositar los objetos a recoger en el portal antes de las 14:00H del día de la recogida.</p>
          }
        </GridItem>
      </GridContainer>)
    }
  }

  const renderBtns = () => {
    return(<GridItem xs={12} sm={12} md={12} style={{marginTop: 15}}>
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          {step > 1 && <Button color="primary" className={classes.btn} style={{backgroundColor: PARAMS.firstColor}} onClick={() => backStep()}>Anterior</Button>}
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
          {step < 4 && <Button color="primary" className={classes.btn} style={{backgroundColor: PARAMS.firstColor}} onClick={() => nextStep()}>Siguente</Button>}
          {step == 4 && <Button color="primary" className={classes.btn} style={{backgroundColor: PARAMS.firstColor}} onClick={() => guardarCita()}>Confirmar</Button>}
          {step == 5 && <Button color="primary" className={classes.btn} style={{backgroundColor: PARAMS.firstColor}} onClick={() => {
            if (editMode) {
              if (!numeroRecogida) {
                grabarEnseres();
              }
            }else{
              if (validatedCode) {
                if (!numeroRecogida) {
                  grabarEnseres();
                }
              }else{
                toast('Introduce el código de confirmación correcto', {type: 'warning'})
              }
            }
          }}>Finalizar</Button>}
        </GridItem>
      </GridContainer>
    </GridItem>);
  }

  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="white"
        routes={[]}
        brand={PARAMS.proyectName}
        rightLinks={<HeaderLinks isLogin={false} />}
        fixed
        isLogin={false}
        {...rest}
      />
      <GridItem xs={12} sm={12} md={12} style={{height: 120, backgroundColor: '#F2F2F2'}}></GridItem>

      <div>
        {renderResumen()}
        <div className={classes.container}>
          <GridContainer justify="center">
            {renderBtns()}
            <GridItem xs={12} sm={12} md={12}>
              <Card className={classes[cardAnimaton]}>
                {initCountdown &&
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', flexDirection: 'row'}} >
                    <div style={{fontSize: 12, fontWeigth: 'bold', fontFamily: 'LatoSemiBold', color: PARAMS.firstColor, marginRight: 5}}>Tiempo de reserva de cita seleccionada</div>
                    <div style={{fontSize: 20, color: PARAMS.firstColor, border:`2px solid ${PARAMS.firstColor}`, borderRadius: 15, width: 80, fontWeight: 'bold', padding: 10, margin: 10}} ref={countdownRef}></div>
                  </div>
                }
                <form className={classes.form} ref={cardRef}>
                  <CardBody style={{padding: 30}}>
                    {renderStep()}

                    {stepNumber != 5 && <GridItem xs={12} sm={12} md={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginTop: 15}}>
                      <GreenCheckbox checked={condicionesUso} onChange={(event) => {
                        setCondicionesUso(event.target.checked)
                      }} name="checkedG" />
                      <p style={{fontSize: 14, fontWeigth: 'bold', fontFamily: 'LatoSemiBold', color: '#8D8D8D', marginTop: 10, marginLeft: 10}}>Debe aceptar las <a style={{color: PARAMS.firstColor}} href='https://www.sadeco.es/enseres/normas-de-uso' target="_blank">condiciones de uso</a></p>
                    </GridItem>}
                  </CardBody>
                  <CardFooter className={classes.cardFooter}></CardFooter>
                </form>
              </Card>
            </GridItem>

            {(step == 1 && calle) && <GridItem xs={12} sm={12} md={12}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardBody style={{padding: 30}}>
                    <GridContainer>
                      <GridItem xs={12} sm={2} md={3} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 0}}>
                        <p style={{fontSize: 14, fontWeigth: 'bold', fontFamily: 'LatoSemiBold', color: '#8D8D8D', paddingLeft: 10}}>Código Postal:</p> <p style={{fontSize: 17, fontWeigth: 'bold', fontFamily: 'LatoSemiBold', color: '#595959', marginBottom: 15, paddingLeft: 10}}>{calle && calle.cp}</p>
                      </GridItem>
                      <GridItem xs={12} sm={10} md={9} style={{display: 'flex', justifyContent: 'center', alingItems: 'center', padding: 0}}>
                        <Maps lat={calle ? calle.lat : null} lng={calle ? calle.lng : null} width="50vw"/>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter className={classes.cardFooter}></CardFooter>
                </form>
              </Card>
            </GridItem>}

            {renderBtns()}
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
