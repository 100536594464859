import Enseres from "views/Enseres/Enseres";
import LandingPage from "views/LandingPage/LandingPage";

const webRoutes = [
  {
    path: "/gestion/:id",
    component: Enseres,
  },
  {
    path: "/",
    component: LandingPage,
  }
];

export default webRoutes;
