// import loginAdminImg from "assets/img/admin_img.jpg";
// import iconImg from "assets/img/logo_header.png";
// import sideBarImg from "assets/img/sidebar-3.jpg";

var params = [];
params['baseUrl'] = 'https://sadecoenseres.aicor.es/';
params['apiUrl'] = 'https://webapitest.sadeco.es:44345/api/';
// params['baseUrl'] = 'https://recogidadomiciliaria.sadeco.es:44346/';
// params['apiUrl'] = 'https://webapi.sadeco.es:44343/api/';
// params['loginAdminImg'] = loginAdminImg;
// params['sideBarIcon'] = iconImg;
// params['sideBarImg'] = sideBarImg;
// "purple", "blue", "green", "orange", "red", "black" ... modificar en material-dashboard-react.js
params['sideBarColor'] = 'black';
params['firstColorRGB'] = '0,158,153';
params['firstColor'] = '#7FBD41';
params['secondColor'] = '#D9D5F5';
params['color_green'] = '#7CC107';
params['color_red'] = '#FF0C56';
params['color_grey'] = '#BBBBBB';
params['proyectName'] = 'Sadeco';
params['defaultTableLength'] = 10;
params['tableLengthArray'] = [10, 25, 50];
params['personalizacion'] = false;
params['dev_mode'] = true;
params['IdEmpresa'] = '00001';
params['tiempoReservaTmp'] = 60*20;
// params['GOOGLE_API_KEY'] = 'AIzaSyAfsDJ2Ee7XCcfvUg1xwEmk-XY5bRfbOO4';
params['GOOGLE_API_KEY'] = 'AIzaSyDBJ3tK7EAR-rOnAm8cFmADakh-Frg_Gkk';

export default params;
