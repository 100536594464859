import { container, customColor } from "assets/jss/material-kit-react.js";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important"
  },
  footer: {
    padding: "0.9375rem 0",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative"
  },
  a: {
    color: customColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  },
  text_title: {
    marginBottom: 30,
    color: "white",
    fontSize: "1em",
    fontFamily: "LatoSemiBold",
    overflow: 'hidden',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    "&:after": {
      background: 'white',
      height: 2,
      flex: 1,
      content: '""'
    }
  },
  text:{
    color: "#b8b8b9",
    fontSize: "0.8em",
    fontFamily: "LatoSemiBold",
    textAlign: 'left'
  },
  footer_block:{
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column'
  },
  grey_block:{
    height: '47px',
    width: '47px',
    backgroundColor: '#a7a7a7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
export default footerStyle;
