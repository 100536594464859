/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import PARAMS from "utils/PARAMS";
import RoomIcon from '@material-ui/icons/Room';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  return (<>
    <footer className={footerClasses} style={{backgroundColor: '#4E4E4F'}}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3} lg={3} className={classes.footer_block} style={{marginBottom: 15}}>
            <div className={classes.text_title}>
              <span>SOBRE NOSOTROS</span><div style={{width: '1px', backgroundColor: 'white'}}/>
            </div>
            <div>
              <div className={classes.text}>
                Sadeco ofrece multitud de servicios enfocados a mejorar la vida de los ciudadanos de Córdoba, en particular, y del medio ambiente en general.
              </div>
              <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                <img style={{width: '72%', marginTop: 30}} id="logo_inferior" src="https://www.sadeco.es/img/system/frontend/sadeco_logo.png"/>
              </div>
            </div>
          </GridItem>

          <GridItem xs={12} sm={6} md={3} lg={3} className={classes.footer_block} style={{marginBottom: 15}}>
            <div className={classes.text_title}>CATEGORÍAS</div>
            <div className={classes.text}>
              <p><a style={{color: '#b8b8b9', textDecoration: 'none'}} href="https://www.sadeco.es">Inicio</a></p>
              <p><a style={{color: '#b8b8b9', textDecoration: 'none'}} href="https://www.sadeco.es/empresa/descripcion-de-la-empresa-historia">Empresa</a></p>
              <p><a style={{color: '#b8b8b9', textDecoration: 'none'}} href="https://www.sadeco.es/informacion-corporativa">Documentación corporativa</a></p>
              <p><a style={{color: '#b8b8b9', textDecoration: 'none'}} href="https://www.sadeco.es/responsabipdad-social/buenas-practicas-tributarias">Responsabipdad social</a></p>
              <p><a style={{color: '#b8b8b9', textDecoration: 'none'}} href="https://www.sadeco.es/atencion-ciudadana/preguntas-frecuentes">Atención ciudadana</a></p>
              <p><a style={{color: '#b8b8b9', textDecoration: 'none'}} href="https://www.sadeco.es/empleo/promocion-interna">Empleo</a></p>
            </div>
          </GridItem>

          <GridItem xs={12} sm={6} md={3} lg={3} className={classes.footer_block} style={{marginBottom: 15}}>
            <div className={classes.text_title}>CONTACTO</div>
            <div className={classes.text} style={{marginBottom: 20, display: 'flex'}}>
              <div className={classes.grey_block}><RoomIcon style={{fontSize: 18, color: '#4E4E4F'}}/></div>
              <div style={{width: '70%'}}>
                <span style={{marginLeft: 10}}>Avda. Medina Azahara 4.</span><br/>
                <span style={{marginLeft: 10}}>14005 Córdoba</span>
              </div>
            </div>
            <div className={classes.text} style={{marginBottom: 20, display: 'flex'}}>
              <div className={classes.grey_block}><PhoneInTalkIcon style={{fontSize: 18, color: '#4E4E4F'}}/></div>
              <div style={{width: '70%'}}>
                <span style={{marginLeft: 10}}>+34 957 76 20 00</span><br/>
                <span style={{marginLeft: 10}}>L-V de 8:00 a 18:00 h</span>
              </div>
            </div>
            <div className={classes.text} style={{marginBottom: 20, display: 'flex'}}>
              <div className={classes.grey_block}><MailOutlineIcon style={{fontSize: 18, color: '#4E4E4F'}}/></div>
              <div style={{width: '70%', display: 'flex', alignItems: 'center'}}>
                <span style={{marginLeft: 10}}>buzon@sadeco.es</span>
              </div>
            </div>
            <div className={classes.text} style={{marginBottom: 20, display: 'flex'}}>
              <div className={classes.grey_block}><QueryBuilderIcon style={{fontSize: 18, color: '#4E4E4F'}}/></div>
              <div style={{width: '70%'}}>
                <span style={{marginLeft: 10}}>ATENCIÓN PRESENCIAL</span><br/>
                <span style={{marginLeft: 10}}>L-V de 9:00 a 14:00 h</span>
              </div>
            </div>
          </GridItem>

          <GridItem xs={12} sm={6} md={3} lg={3} className={classes.footer_block} style={{marginBottom: 15}}>
            <div className={classes.text_title}>INFORMACIÓN LEGAL</div>
            <div className={classes.text}>
              <p><a style={{color: '#b8b8b9', textDecoration: 'none'}} href="https://www.sadeco.es/poptica-de-privacidad">Política de privacidad</a></p>
              <p><a style={{color: '#b8b8b9', textDecoration: 'none'}} href="https://www.sadeco.es/poptica-de-cookies">Política de cookies</a></p>
              <p><a style={{color: '#b8b8b9', textDecoration: 'none'}} target="_blank" href="https://www.sadeco.es/storage/files/medias/2020/04/registro-actividades-sadeco.pdf">Registro de Actividades</a></p>
              <p><a style={{color: '#b8b8b9', textDecoration: 'none'}} href="https://www.sadeco.es/aviso-legal">Aviso Legal</a></p>
              <p><a style={{color: '#b8b8b9', textDecoration: 'none'}} href="https://www.sadeco.es/storage/files/medias/2020/04/form-ejercicio-derechos.pdf">Formulario Ejercicio de derechos</a></p>
              <p>
                <a style={{color: '#b8b8b9', textDecoration: 'none'}} href="https://www.facebook.com/sadeco.cordoba" target="_blank">
                  <img style={{width: '12%', marginRight: 2}} src="https://www.sadeco.es/img/system/frontend/icono_facebook.png"/>
                </a>
                <a style={{color: '#b8b8b9', textDecoration: 'none'}} href="https://twitter.com/sadecocordoba" target="_blank">
                  <img style={{width: '12%', marginRight: 2}} src="https://www.sadeco.es/img/system/frontend/icono_twitter.png"/>
                </a>
                <a style={{color: '#b8b8b9', textDecoration: 'none'}} href="https://www.youtube.com/user/CanalSadeco/" target="_blank">
                  <img style={{width: '12%', marginRight: 2}} src="https://www.sadeco.es/img/system/frontend/icono_youtube.png"/>
                </a>
                <a style={{color: '#b8b8b9', textDecoration: 'none'}} href="https://www.instagram.com/sadecocordoba/" target="_blank">
                  <img style={{width: '12%', marginRight: 2}} src="https://www.sadeco.es/img/system/frontend/icono_instagram.jpg"/>
                </a>
              </p>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </footer>
    <footer className={footerClasses} style={{backgroundColor: '#212121', height: 70}}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={8} sm={6} md={6} lg={6} className={classes.footer_block} style={{display: 'flex', alignItems: 'flex-start'}}>
            <p className={classes.text}>
              <a style={{color: 'white', textDecoration: 'none', marginRight: 10}} href="https://www.sadeco.es/politica-de-privacidad">Política de privacidad</a>
              <a style={{color: 'white', textDecoration: 'none', marginRight: 10}} href="https://www.sadeco.es/politica-de-cookies">Política de cookies</a>
              <a style={{color: 'white', textDecoration: 'none', marginRight: 10}} href="https://www.sadeco.es/aviso-legal">Aviso Legal</a>
            </p>
          </GridItem>
          <GridItem xs={4} sm={6} md={6} lg={6} className={classes.footer_block} style={{display: 'flex', alignItems: 'flex-end'}}>
            <p className={classes.text} style={{color: 'white'}}>
              <span>Diseñada por  </span>
              <a style={{color: '#FF0066', textDecoration: 'none'}} href="https://www.aicor.com">www.aicor.com</a>
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </footer>
  </>);
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
