import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import PARAMS from "utils/PARAMS";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {

  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{marginBottom: 30}}>
            <p style={{fontSize: 14, color: '#999999'}}>Debe tener en cuenta que para optimizar el tratamiento y la recuperación de los elementos a recoger,  la recogida de enseres habituales y de electrodomésticos (RAEE) se realiza manera diferenciada, por lo que se debe realizar, en caso de ser necesario, en dos solicitudes independientes para su recogida por nuestro servicio</p>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} onClick={() => {window.location.href = `${PARAMS.baseUrl}gestion/enseres`}} style={{cursor: 'pointer', marginBottom: 30}}>
            <img src={require("assets/img/enseres-logo.png")} alt="Sadeco"  style={{width: 80, marginBottom: -25}}/>
            <div style={{height: 250}}>
              <InfoArea
                title="Enseres"
                description="Se consideran enseres, los muebles u objetos de uso diario o de decoración de un domicilio tales como, camas, mesas, sillas, armarios, etc., que por sus dimensiones no pueden ser depositados en los contenedores instalados en la vía pública."
                img='src/assets/img/enseres-logo.png'
                iconColor="info"
                vertical
              />
            </div>
            <Button color="primary" style={{backgroundColor: PARAMS.firstColor, fontSize: 17}} onClick={() => {window.location.href = `${PARAMS.baseUrl}gestion/enseres`}}>Seleccionar</Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} onClick={() => {window.location.href = `${PARAMS.baseUrl}gestion/raee`}} style={{cursor: 'pointer'}}>
            <img src={require("assets/img/otros-logo.png")} alt="Sadeco"  style={{width: 80, marginBottom: -25}}/>
            <div style={{height: 250}}>
              <InfoArea
                title="RAEE"
                description="Los residuos de aparatos eléctricos y electrónicos (RAEE) son aquellos elementos que utilizamos diariamente, como cepillos de dientes eléctrico, secadora de pelo, tablets, lavadoras, teléfonos, etc., que cuando dejan de funcionar se vuelven inservibles y pasan a ser considerados residuos."
                img='src/assets/img/otros-logo.png'
                iconColor="success"
                vertical
                style={{height: 250}}
              />
            </div>
            <Button color="primary" style={{backgroundColor: PARAMS.firstColor, fontSize: 17}} onClick={() => {window.location.href = `${PARAMS.baseUrl}gestion/raee`}}>Seleccionar</Button>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
