import React, { useEffect, useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import listGridPlugin from '@fullcalendar/list';
// import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import Moment from "moment";
import GridContainer from "components/Grid/GridContainer.js";
// import API from "utils/API";
// import PARAMS from "utils/PARAMS";

Moment.locale("es");

export default function FullCalendarComponent(props) {
  const [calendarEvents, setCalendarEvents] = useState(props.events);

  const calendarComponentRef = useRef(null);
  // const getApi = () => {
  //   const { current: calendarDom } = calendarComponentRef;
  //   return calendarDom ? calendarDom.getApi() : null;
  // }

  const eventClicK = info => {
    for (var i = 0; i < document.getElementsByClassName("fc-daygrid-event").length; i++) {
      document.getElementsByClassName("fc-daygrid-event")[i].classList.remove('fc-event-selected-custom')
    }
    document.getElementsByClassName(`selected-event_${info.event.id}`)[0].classList.add('fc-event-selected-custom')
    props.setDate(calendarEvents[info.event.id].data);
    props.setDateId(info.event.id);
  }

  useEffect(() => {
    if (props.dateId) {
      setTimeout(function () {
        document.getElementsByClassName(`selected-event_${props.dateId}`)[0].classList.add('fc-event-selected-custom')
      }, 1000);
    }
  }, []);

  return (
    <GridContainer justify="center" alignItems="center">
      <div style={{minWidth: '65vw', maxWidth: '75vw'}}>
        <FullCalendar
          plugins={[ dayGridPlugin ]}
          defaultView="dayGridMonth"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: ''
          }}
          locales={esLocale}
          locale='es'
          firstDay={1}
          ref={calendarComponentRef}
          weekends={false}
          events={calendarEvents}
          businessHours={[{
            daysOfWeek: [ 1, 2, 3, 4, 5 ],
            startTime: props.horario.start,
            endTime: props.horario.end
          }]}
          eventConstraint="businessHours"
          eventClick={(info) => eventClicK(info)}
        />
      </div>
    </GridContainer>
  )

}
