import React, { Component } from "react";

import PARAMS from "utils/PARAMS";
import GridContainer from "components/Grid/GridContainer";
import Loader from "react-spinners/RingLoader";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import rutas from "routes.js";

// core components
import { createBrowserHistory } from "history";
import { createGlobalStyle } from 'styled-components';
import latoItalic from 'assets/font/Lato-Italic.ttf';
import latoBlack from 'assets/font/Lato-Black.ttf';
import latoBold from 'assets/font/Lato-Bold.ttf';
import latoSemiBold from 'assets/font/Lato-Semibold.ttf';
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'LatoItalic';
    src: url(${latoItalic});
  }
  @font-face {
    font-family: 'LatoBlack';
    src: url(${latoBlack});
  }
  @font-face {
    font-family: 'LatoBold';
    src: url(${latoBold});
  }
  @font-face {
    font-family: 'LatoSemiBold';
    src: url(${latoSemiBold});
  }
`;

const hist = createBrowserHistory();

class App extends Component {
  state = {
    apiToken: localStorage.getItem("apiToken") || "",
    user: localStorage.getItem("userType") || "entidad",
    admin: false,
    load: false,
    permiso: null
  };

  setApiToken = token => {
    this.setState({ apiToken: token });
  };

  componentDidMount(){
    this.setState({ load: true });
  }

  render() {
    if (this.state.load) {
      return (
        <>
          <GlobalStyle/>
          <Router history={hist}>
            <Switch>
              {rutas.map((prop, key) => {
                let Componente = prop.component;
                return (
                  <Route
                    path={prop.path}
                    component={(props) => <Componente {...props}/>}
                    key={key}
                  />
                );
              })}
              <Redirect from="/" exact={true} to="/landingPage" />
              {/*<Route path='*' component={ NotFound }/>*/}
            </Switch>
          </Router>
        </>
      );
    }else{
      return(
        <GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
          <Loader color={PARAMS.firstColor} size={80} />
        </GridContainer>
      )
    }

  }
}

export default App;
